body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  width: 100%;
  height: 100%;
}
::-moz-placeholder {
  color: #ddd !important;
  font-weight: normal;
}

::-webkit-input-placeholder {
  font-weight: normal;
  color: #ddd !important;
}

:-ms-input-placeholder {
  font-weight: normal;
  color: #ddd !important;
}

a {
  text-decoration: none;
  color: #000;
}

input {
  outline: none;
  border: none;
  -webkit-appearance: none; /*去除系统默认的样式*/
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 10rem white inset !important;
}
@-webkit-keyframes rotating {
  0% {
      -webkit-transform: rotate(0);
              transform: rotate(0)
  }
  100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg)
  }
}
@keyframes rotating {
  0% {
      -webkit-transform: rotate(0);
              transform: rotate(0)
  }
  100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg)
  }
}
.icon-loading{
  font-size: 0.32rem!important;
  display: inline-block;
  -webkit-animation: rotating 2s linear infinite;
          animation: rotating 2s linear infinite
}
img{
  outline-width:0;
  vertical-align:top;
}
.App{
  min-height: 100vh;
  background-color: #f2f2f2;
  /* padding: 100px 0; */
}
button:disabled {
  opacity: 0.5!important;
}